<template>
  <div class="pad-3">
    <div class="bb-container bb-container--lista">
      <div class="cta-contacto">
        <div class="cta-contacto__title">{{ $t("generic.doYouNeedHelp") }}</div>
        <p>
          {{ $t("generic.ctaHelp1") }} 
          <a 
          class="cta-contacto__link link link--underline"
          href="/preguntas-frecuentes">{{ $t("generic.ctaHelp2") }}</a> {{ $t("generic.ctaHelp3") }}
          <a
          class="cta-contacto__link link link--underline"
          href="mailto:hola@hellobb.net"
          >{{ $t("generic.ctaHelp4") }}</a>.
      </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CtaContact",
};
</script>
